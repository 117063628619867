import { ChatType } from "enums/chatType";
import { AppConfig } from "enums/config";
import { UserRoles } from "enums/roles";
import { SessionEngine } from "enums/sessionEngine";
import { SessionType } from "enums/sessions";
import { Sort } from "enums/sort";
import { cloneDeep, omit } from "lodash";
import gacaRecordingService, {
  gacaGroups,
} from "services/recordingGaca.service";
import {
  commonMsgType,
  directMsg,
  email,
  googleType,
  instagramPost,
  linkedInpost,
  twitterPost,
  whatsappMsg,
  youtubeType,
} from "./messageType";
import { IntialState } from "./type";
import {
  ACTIVE_SESSION_OFFSET,
  ADD_ITEM_LIST_AGENT_SESSION,
  AGENT_TAKE_SESSION_SUCCESS,
  APPLY_FILTERS,
  ASSIGN_ESCALATED_SESSION_OFFSET,
  CLEAR_SELECTED_SESSION,
  CLIENT_SESSION_OFFSET,
  CLOSE_SESSION_OFFSET,
  DELETE_DRAFT_MESSAGE,
  END_VIDEO_CALL,
  ESCALATED_SESSION_OFFSET,
  ESCALATE_SESSION,
  GET_CLIENT_INFO,
  GET_SESSION_MSG,
  INACTIVE_SESSION_OFFSET,
  LEAVE_SESSION_SUCCESS,
  LIST_AGENT_SESSION,
  LIST_CLIENT_SESSION,
  LIST_ESCALATED_SEESION,
  LOADING_REACTION_LIST,
  LOAD_AGENT_SEESION,
  LOAD_CLIENT_SESSION,
  LOAD_ESCALATED_SEESION,
  LOAD_SESSION_MSG,
  MESSAGE_LIMIT_STATUS,
  MESSAGE_STATUS,
  MOVE_ITEM_ARCHIVE_TO_LIST_SESSION,
  RECEIVE_NEW_MESSAGE,
  RECEIVE_NEW_REACTION,
  RECEIVE_REACTION_LIST,
  RESET_SELECT_SESSION,
  RETURN_TO_AGENT,
  SAVE_DRAFT_MESSAGE,
  //   ACTIVE_INACTIVE_SESSION_OFFSET,
  SELECT_SESSION,
  SEND_ACKNOWLEDGE,
  SEND_MSG_API,
  SEND_UPDATE_PENDING,
  SESSION_AGENT_FILTER,
  SESSION_CLOSED_SUCCESS,
  SESSION_GROUP_FILTER,
  SESSION_MSG_OFFSET,
  SESSION_OPEN_SUCCESS,
  SET_CLIENT_INFO,
  SET_DONE_SESSION,
  START_VIDEO_CALL,
  TAKE_SESSION,
  TAKE_SESSION_SUCCESS,
  TRANSFER_GROUP,
  UPDATE_CURRENT_SESSION_TYPE,
  UPDATE_MULTI_SELECTED_SESSIONS,
  UPDATE_VALUE_ENABLE_SEARCH,
  VIDEO_CALL_PARTICIPANTS,
} from "./types";
import {
  filterIncomingEvents,
  filterSessions,
  getReactionProperty,
  twitterRecieveMsg,
  updateReplyStatus,
  //   updateSessions,
  updateTotalSessionsByUserSessionOpen,
  updateTweetsAndReplies,
} from "./utils";

const initialState: IntialState = {
  loginStatus: null,
  loaddingSession: false,
  loadingActiveSession: false,
  loadingInactiveSession: false,
  loadingClosedSession: false,
  loadingEscalatedSession: false,
  loadingAssignedEscalatedSession: false,
  sessionsActive: {},
  sessionsInactive: {},
  sessionsClosed: {},
  escaletedAssigned: {},
  escalatedUnassigned: {},
  activeCount: 0,
  inactiveCount: 0,
  searchText: "",
  closeSessionDone: false,
  closeOffsetTrack: 0,
  activeInactiveOffsetTrack: 0,
  activeOffsetTrack: 0,
  inactiveOffsetTrack: 0,
  assignEscalatedOffsetTrack: 0,
  escalatedOffsetTrack: 0,
  selectedSession: null,
  msgLimitStatus: null,
  loadingMessages: false,
  sessionMessages: {
    messages: [],
    messageKey: "",
    messageType: SessionEngine.none,
    sessionType: ChatType.none,
    username: "",
    profileImage: "",
    clientName: "",
    replies: [],
    getAnotherPatch: false,
    sessionKey: " ",
    agentMsgs: [],
    clientMsgs: [],
  },
  draftMessages: {},
  messagesOffset: 0,
  chatType: ChatType.none,
  activeSessionDone: false,
  inactiveSessionDone: false,
  escalatedSessionDone: false,
  assignEscalatedSessionDone: false,
  openSession: false,
  openSessionStatus: null,
  openedSession: null,
  receiveNewMessage: null,
  selectedSessionData: null,
  sendingMessage: false,
  globalUnreadCount: 0,
  globalEscalatedUnreadCount: 0,
  selectedSessionClientInfo: {
    name: "",
    nin: "",
    username: "",
  },
  sessionActiveFilter: {},
  sessionInactiveFilter: {},
  sessionEscalatedFilter: {},
  sessionClosedFilter: {},
  sessionsUnassignEscalatedFilter: {},
  agentFilter: [],
  groupFilter: [],
  currentFilters: {
    dateSent: "DESC",
    sessionType: "",
    clientName: "",
    userName: "",
    ChannelIDs: [],
  },
  isEnableSearch: false,
  transferSession: {},
  joinedVideoCall: false,
  videoCallParticipants: [],
  loadingClientSession: false,
  clientSessions: {},
  clientOffsetTrack: 0,
  clientSessionDone: false,
  reactionList: {},
  loadingReactionList: false,
  multiSelectedSessions: [],
  currentSessionType: null,
};

export default function sessionReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  let sessionActive: any = state.sessionsActive;
  let sessionInactive: any = state.sessionsInactive;
  let sessionClosed: any = state.sessionsClosed;
  let escaletedAssigned: any = state.escaletedAssigned;
  let escalatedUnassigned: any = state.escalatedUnassigned;

  const formatToObject = (data: { sessionKey: string }[]) => {
    let finalFormat: object = {};
    data.forEach((item: { sessionKey: string }) => {
      finalFormat = {
        ...finalFormat,
        [item.sessionKey]: item,
      };
    });
    return finalFormat;
  };

  const sortSessions = (sessions: {}) => {
    const arr = Object.values(sessions);
    arr.sort((a: any, b: any) => {
      if (localStorage.getItem("sort") === Sort.Desc) {
        // Newest first
        return a.dateSent < b.dateSent ? 1 : a.dateSent > b.dateSent ? -1 : 0;
      }
      //Oldest
      return a.dateSent < b.dateSent ? -1 : a.dateSent > b.dateSent ? 1 : 0;
    });
    return arr.reduce((obj: any, item: any) => {
      obj[item["sessionKey"]] = item;
      return obj;
    }, {});
  };

  const filterAgentAndGroup = (
    active: any,
    inactive: any,
    assignEsclated: any,
    closed: any,
    unassignedEscalated: any
  ) => {
    // check if user filter session by agent or group we reflect that filter for new data
    let filterData;
    if (state.agentFilter.length > 0 || state.groupFilter.length > 0) {
      filterData = filterSessions(
        state.groupFilter,
        state.agentFilter,
        active,
        inactive,
        assignEsclated,
        closed,
        unassignedEscalated
      );
    }

    let filterDataUpdate = filterData
      ? {
          ...(filterData?.sessionActiveFilter
            ? { sessionActiveFilter: filterData.sessionActiveFilter }
            : {}),
          ...(filterData?.sessionInactiveFilter
            ? { sessionInactiveFilter: filterData.sessionInactiveFilter }
            : {}),
          ...(filterData?.sessionEscalatedFilter
            ? { sessionEscalatedFilter: filterData.sessionEscalatedFilter }
            : {}),
          ...(filterData?.sessionClosedFilter
            ? { sessionClosedFilter: filterData.sessionClosedFilter }
            : {}),
          ...(filterData?.sessionsUnassignEscalatedFilter
            ? {
                sessionsUnassignEscalatedFilter:
                  filterData.sessionsUnassignEscalatedFilter,
              }
            : {}),
        }
      : {};
    return filterDataUpdate;
  };

  switch (type) {
    case LOAD_AGENT_SEESION:
      return {
        ...state,
        loaddingSession: true,
        ...(payload === "active"
          ? { loadingActiveSession: true }
          : payload === "inactive"
          ? { loadingInactiveSession: true }
          : { loadingClosedSession: true }),
      };
    case LOAD_ESCALATED_SEESION:
      // "active" | "inactive"
      return {
        ...state,
        loaddingSession: true,
        ...(payload === "active"
          ? { loadingAssignedEscalatedSession: true }
          : { loadingEscalatedSession: true }),
      };
    case LOAD_CLIENT_SESSION: {
      return {
        ...state,
        loaddingSession: true,
        loadingClientSession: true,
      };
    }

    case SET_DONE_SESSION:
      return {
        ...state,
        closeOffsetTrack: 0,
        activeInactiveOffsetTrack: 0,
        activeOffsetTrack: 0,
        inactiveOffsetTrack: 0,
        assignEscalatedOffsetTrack: 0,
        escalatedOffsetTrack: 0,
        activeSessionDone: false,
        inactiveSessionDone: false,
        escalatedSessionDone: false,
        assignEscalatedSessionDone: false,
        sessionsActive: {},
        sessionsInactive: {},
        sessionsClosed: {},
        escaletedAssigned: {},
        escalatedUnassigned: {},
      };
    case LIST_ESCALATED_SEESION: {
      let unreadMessageCount = 0;
      let dataFormat: any = formatToObject(payload.sessionPool);

      if (payload.sessionStatus === SessionType.ESCALATED) {
        let trackCheck =
          state.escalatedOffsetTrack === 0
            ? sortSessions(dataFormat)
            : sortSessions({ ...escalatedUnassigned, ...dataFormat });
        return {
          ...state,
          loaddingSession: false,
          loadingEscalatedSession: false,
          escalatedUnassigned: trackCheck,
          escalatedSessionDone:
            payload.sessionPool.length < AppConfig.GET_SESSIONS_PATCH_SIZE,
          //   ...filterAgentAndGroup(null, null, null, null, trackCheck),
        };
      } else if (payload.sessionStatus === SessionType.ASSIGNED_ESCALATED) {
        for (let item in dataFormat) {
          dataFormat[`${item}`].unreadCount
            ? (unreadMessageCount += dataFormat[item].unreadCount)
            : (unreadMessageCount += 0);
        }
        let trackCheck =
          state.assignEscalatedOffsetTrack === 0
            ? sortSessions(dataFormat)
            : sortSessions({ ...escaletedAssigned, ...dataFormat });
        return {
          ...state,
          loaddingSession: false,
          loadingAssignedEscalatedSession: false,
          escaletedAssigned: trackCheck,
          assignEscalatedSessionDone:
            payload.sessionPool.length < AppConfig.GET_SESSIONS_PATCH_SIZE,
          globalEscalatedUnreadCount:
            state.globalEscalatedUnreadCount === unreadMessageCount
              ? unreadMessageCount
              : state.globalEscalatedUnreadCount + unreadMessageCount,
          //   ...filterAgentAndGroup(null, null, trackCheck, null, null),
        };
      } else {
        return state;
      }
    }
    case LIST_AGENT_SESSION:
      let unreadMessageCount = 0;
      let dataFormat = formatToObject(payload.sessionPool);
      if (payload.sessionStatus === SessionType.ASSIGNED) {
        let trackCheck: any =
          state.activeOffsetTrack === 0
            ? sortSessions(dataFormat)
            : sortSessions({ ...sessionActive, ...dataFormat });

        Object.keys(trackCheck).forEach(function (key, index) {
          trackCheck[key].unreadCount
            ? (unreadMessageCount += trackCheck[key].unreadCount)
            : (unreadMessageCount += 0);
        });
        return {
          ...state,
          loaddingSession: false,
          loadingActiveSession: false,
          sessionsActive: trackCheck,
          activeCount: payload.activeCount,
          inactiveCount: payload.inactiveCount,
          activeSessionDone:
            payload.sessionPool.length < AppConfig.GET_SESSIONS_PATCH_SIZE,
          globalUnreadCount:
            state.globalUnreadCount === unreadMessageCount
              ? unreadMessageCount
              : unreadMessageCount,
          ...filterAgentAndGroup(trackCheck, null, null, null, null),
        };
      } else if (payload.sessionStatus === SessionType.UNASSIGNED) {
        let trackCheck =
          state.inactiveOffsetTrack === 0
            ? sortSessions(dataFormat)
            : sortSessions({ ...sessionInactive, ...dataFormat });
        return {
          ...state,
          loaddingSession: false,
          loadingInactiveSession: false,
          sessionsInactive: trackCheck,
          //sessionsInactive: { ...sessionInactive, ...dataFormat },
          inactiveSessionDone:
            payload.sessionPool.length < AppConfig.GET_SESSIONS_PATCH_SIZE,
          ...filterAgentAndGroup(null, trackCheck, null, null, null),
        };
      } else if (payload.sessionStatus === SessionType.CLOSED) {
        let trackCheck =
          state.closeOffsetTrack === 0
            ? sortSessions(dataFormat)
            : sortSessions({ ...state.sessionsClosed, ...dataFormat });
        return {
          ...state,
          loaddingSession: false,
          loadingClosedSession: false,
          sessionsClosed: trackCheck,
          //sessionsClosed: { ...state.sessionsClosed, ...dataFormat },
          closeSessionDone:
            payload.sessionPool.length < AppConfig.GET_SESSIONS_PATCH_SIZE,
          ...filterAgentAndGroup(null, null, null, trackCheck, null),
        };
      } else {
        return state;
      }
    case LIST_CLIENT_SESSION: {
      let dataFormat = formatToObject(payload.sessionPool);

      if (payload.sessionStatus === "") {
        let trackCheck =
          state.clientOffsetTrack === 0
            ? sortSessions(dataFormat)
            : sortSessions({ ...state.clientSessions, ...dataFormat });
        return {
          ...state,
          loaddingSession: false,
          loadingClientSession: false,
          clientSessions: trackCheck,
          clientSessionDone:
            payload.sessionPool.length < AppConfig.GET_SESSIONS_PATCH_SIZE,
        };
      } else {
        return state;
      }
    }
    case MOVE_ITEM_ARCHIVE_TO_LIST_SESSION:
      let dataItem = formatToObject([payload?.data]);
      // console.log("before", size(sessionClosed),sessionClosed[payload?.oldSession])
      delete sessionClosed[payload?.oldSession];
      const newActiveCount = state.activeCount + 1;
      // console.log("after",size(sessionClosed),sessionClosed[payload?.oldSession])
      if (payload?.data?.sessionStatus === SessionType.ASSIGNED) {
        return {
          ...state,
          loaddingSession: false,
          activeCount: newActiveCount,
          sessionsActive: sortSessions({ ...sessionActive, ...dataItem }),
          sessionClosed: sessionClosed,
        };
      } else {
        return state;
      }
    case ADD_ITEM_LIST_AGENT_SESSION:
      let dataFormat2 = formatToObject([payload]);
      if (payload.sessionStatus === SessionType.ASSIGNED) {
        return {
          ...state,
          loaddingSession: false,
          sessionsActive: sortSessions({ ...sessionActive, ...dataFormat2 }),
        };
      } else if (payload.sessionStatus === SessionType.CLOSED) {
        return {
          ...state,
          loaddingSession: false,
          sessionsClosed: sortSessions({
            ...dataFormat2,
            ...state.sessionsClosed,
          }),
        };
      } else {
        return state;
      }
    case CLOSE_SESSION_OFFSET:
      return {
        ...state,
        closeOffsetTrack: Object.keys(sessionClosed).length === 0 ? 0 : payload,
      };
    case ACTIVE_SESSION_OFFSET:
      return {
        ...state,
        activeOffsetTrack: payload,
      };
    case INACTIVE_SESSION_OFFSET:
      return {
        ...state,
        inactiveOffsetTrack: payload,
      };
    case ASSIGN_ESCALATED_SESSION_OFFSET:
      return {
        ...state,
        assignEscalatedOffsetTrack: payload,
      };
    case ESCALATED_SESSION_OFFSET:
      return {
        ...state,
        escalatedOffsetTrack: payload,
      };
    case CLIENT_SESSION_OFFSET:
      return {
        ...state,
        clientOffsetTrack: payload,
      };
    case SELECT_SESSION: {
      /* Below code is requested as part of integration with gaca recording  */
      /* this can be removed in future without any effect on functionally */
      if (
        state?.selectedSessionData?.sessionStatus === SessionType.ASSIGNED &&
        gacaGroups.includes(state?.selectedSessionData?.groupID)
      ) {
        gacaRecordingService.ticketPause({
          interactionId: state?.selectedSession || "0",
          siteId: state?.selectedSessionData?.groupID || "gaca",
        });
      }
      /* end block  */

      const selectedActiveSession = {
        ...sessionActive[payload],
        // unreadCount: null,
      };
      const selectedInactiveSession = {
        ...sessionInactive[payload],
        // unreadCount: null,
      };
      let checkSession = sessionActive[payload]
        ? selectedActiveSession
        : sessionInactive[payload]
        ? selectedInactiveSession
        : sessionClosed[payload]
        ? sessionClosed[payload]
        : null;
      // check if this is the same opened session to update client info
      const checkUserInfo =
        payload === state?.selectedSessionData?.sessionKey
          ? {
              ...state.selectedSessionClientInfo,
              name: checkSession.clientName,
              username: checkSession.username,
              accountID: checkSession.accountID,
              thumbURL: checkSession.profileImage,
              companyID: checkSession.channel.CompanyID,
              sessionStatus: checkSession.sessionStatus,
              sessionKey: checkSession.sessionKey,
              clientID: checkSession.clientID,
            }
          : null;

      // update global unread count
      let newGlobalUnreadCount =
        checkSession.sessionStatus !== SessionType.ASSIGNED ||
        !state.sessionsActive[checkSession.sessionKey]
          ? null
          : checkSession.unreadCount &&
            checkSession.sessionStatus === SessionType.ASSIGNED &&
            state.globalUnreadCount - checkSession.unreadCount >= 0
          ? state.globalUnreadCount - checkSession.unreadCount
          : null;

      return {
        ...state,
        selectedSession: payload,
        sessionMessages: {
          messages: [],
          messageKey: "",
          messageType: SessionEngine.none,
          sessionType: ChatType.none,
          username: "",
          profileImage: "",
          clientName: "",
          replies: [],
          getAnotherPatch: false,
          sessionKey: " ",
          currentSessionAllMessagesLoaded: false,
          reactionList: {}, // clear reaction list when new session is selected
        },
        ...(checkSession ? { selectedSessionData: checkSession } : {}),
        ...(checkUserInfo ? { selectedSessionClientInfo: checkUserInfo } : {}),
        chatType: "",
        joinedVideoCall: false,
        videoCallParticipants: [],
        clientSessions: {},
        ...(newGlobalUnreadCount || newGlobalUnreadCount === 0
          ? { globalUnreadCount: newGlobalUnreadCount }
          : {}),
      };
    }

    case RESET_SELECT_SESSION:
      return {
        ...state,
        selectedSession: null,
        selectedSessionData: null,
        chatType: "",
      };
    case AGENT_TAKE_SESSION_SUCCESS: {
      if (
        payload.sessionStatus === SessionType.ASSIGNED &&
        payload.updateStatus === "SUCCESS"
      ) {
        delete state.sessionsActive[payload.sessionKey];
        const newActive = { ...state.sessionsActive };
        return {
          ...state,
          activeCount: state.activeCount - 1,
          sessionsActive: newActive,
          payload,
        };
      }
      if (
        payload.sessionStatus === SessionType.UNASSIGNED &&
        payload.updateStatus === "SUCCESS"
      ) {
        delete state.sessionsInactive[payload.sessionKey];
        const newInactive = { ...state.sessionsInactive };
        return {
          ...state,
          inactiveCount: state.inactiveCount - 1,
          sessionsInactive: newInactive,
          payload,
        };
      }
      return {
        ...state,
      };
    }
    case SEND_ACKNOWLEDGE: {
      let oldSession: any = sessionActive;
      let sessionKey: any = payload;
      if (oldSession[sessionKey]) {
        oldSession[sessionKey].unreadCount = null;
      }
      return {
        ...state,
        sessionsActive: oldSession,
      };
    }
    case MESSAGE_LIMIT_STATUS:
      return {
        ...state,
        msgLimitStatus: payload,
      };
    case GET_SESSION_MSG:
      return {
        ...state,
        loadingMessages: true,
      };
    case RECEIVE_NEW_MESSAGE: {
      // Check if it is a direct message
      // console.log({ payload });

      //   let sessionMsgs =sessionState.messages ? sessionState.messages : sessionState.replies;
      //   console.log({ sessionMsgs, active: sessionActive[payload.sessionKey] })
      if (sessionActive[payload.sessionKey] || payload.type === "session") {
        sessionActive[payload.sessionKey] = {
          ...payload,
          ...sessionActive[payload.sessionKey],
          clientName:
            sessionActive[payload.sessionKey]?.clientName ??
            payload?.clientName,
          message: payload.message,
          messageStatus: payload.messageStatus,
          unreadCount: payload.unreadCount,
        };
      }

      if (sessionInactive[payload.sessionKey]) {
        sessionInactive[payload.sessionKey] = {
          ...sessionActive[payload.sessionKey],
          message: payload.message,
          messageStatus: payload.messageStatus,
          unreadCount: payload.unreadCount,
        };
      }

      if (escaletedAssigned[payload.sessionKey]) {
        escaletedAssigned[payload.sessionKey] = {
          ...escaletedAssigned[payload.sessionKey],
          message: payload.message,
          messageStatus: payload.messageStatus,
          unreadCount: payload.unreadCount,
        };
      }

      let targetUpdate = sessionActive[payload.sessionKey]
        ? { sessionsActive: sortSessions(sessionActive) }
        : sessionInactive[payload.sessionKey]
        ? { sessionsInactive: sessionInactive }
        : escaletedAssigned[payload.sessionKey]
        ? { escaletedAssigned: escaletedAssigned }
        : {};

      // check if the new message for the current agent
      const forCurrentUser =
        sessionActive[payload.sessionKey] ||
        escaletedAssigned[payload.sessionKey];

      let sessionMsgs = cloneDeep(state?.sessionMessages?.messages);

      if (sessionMsgs && payload.sessionKey === state.selectedSession) {
        const messagesIndex = sessionMsgs
          .map((item: any) => item.messageKey)
          .indexOf(payload.messageKey);
        let messages: any = sessionMsgs;

        if (messagesIndex === -1) {
          messages.push({ ...payload, last: true });
        }

        // console.log({ messagesIndex })
        // reset the last field marker in the last message to false
        if (
          messages[messages.length - 2] &&
          messages[messages.length - 2].senderKey ===
            messages[messages.length - 1].senderKey
        ) {
          messages[messages.length - 2].last = false;
        }
        if (messagesIndex !== -1) {
          messages.splice(messagesIndex, 1, { ...payload, last: true });
        }

        // if message was a twitter or linkedIn one
        if (
          commonMsgType.includes(payload?.sessionType) &&
          payload.VendorParentIDs
        ) {
          twitterRecieveMsg({
            messagesIndex,
            payload,
            sessionMsgs,
            messages,
          });
        }

        let globalUnreadCount =
          forCurrentUser &&
          payload.hasOwnProperty("unreadCount") &&
          payload.unreadCount !== null &&
          payload.sessionKey !== state.selectedSession
            ? { globalUnreadCount: 1 + state.globalUnreadCount }
            : {};

        let globalEsclatedCount =
          forCurrentUser &&
          payload.hasOwnProperty("unreadCount") &&
          payload.unreadCount !== null &&
          payload.sessionKey !== state.selectedSession
            ? {
                globalEscalatedUnreadCount:
                  1 + state.globalEscalatedUnreadCount,
              }
            : {};

        const { senderType, message, dateSent } = payload;
        const { agentMsgs, clientMsgs } = state?.sessionMessages || {};

        const createMessage = (text: string, time: string) => ({
          Text: text,
          Time: time,
        });

        const newAgentMsgs = ["agent", "chatbot"].includes(senderType)
          ? [...(agentMsgs || []), createMessage(message, dateSent)]
          : agentMsgs || [];

        const newClientMsgs =
          senderType === "client"
            ? [...(clientMsgs || []), createMessage(message, dateSent)]
            : clientMsgs || [];
        return {
          ...state,
          sessionMessages: {
            ...state.sessionMessages,
            messages: [...messages],
            // chatbot has been added due to bug in receiving message from BE
            agentMsgs: newAgentMsgs,
            clientMsgs: newClientMsgs,

            ...(email.includes(payload?.sessionType)
              ? {
                  emails: {
                    ...(state?.sessionMessages?.emails || {}),
                    replies: [
                      ...(state?.sessionMessages?.emails?.replies || []),
                      { ...payload },
                    ],
                  },
                }
              : {}),
          },
          receiveNewMessage: payload,
          ...targetUpdate,
          ...(state.selectedSession === payload.sessionKey
            ? { sendingMessage: false }
            : {}),
          ...globalUnreadCount,
          ...globalEsclatedCount,
        };
      } else {
        let esclatedUnreadCount =
          forCurrentUser &&
          payload.hasOwnProperty("unreadCount") &&
          payload.unreadCount !== null &&
          payload.sessionKey !== state.selectedSession &&
          payload.sessionStatus === SessionType.ASSIGNED_ESCALATED
            ? {
                globalEscalatedUnreadCount:
                  1 + state.globalEscalatedUnreadCount,
              }
            : {};

        let gloableUnreadCount =
          forCurrentUser &&
          payload.hasOwnProperty("unreadCount") &&
          payload.unreadCount !== null &&
          payload.sessionKey !== state.selectedSession &&
          payload.sessionStatus === SessionType.ASSIGNED
            ? { globalUnreadCount: 1 + state.globalUnreadCount }
            : {};
        return {
          ...state,
          activeCount:
            payload.type === "session"
              ? state.activeCount + 1
              : state.activeCount,
          receiveNewMessage: payload,
          ...targetUpdate,
          ...(state.selectedSession === payload.sessionKey
            ? { sendingMessage: false }
            : {}),
          ...gloableUnreadCount,
          ...esclatedUnreadCount,
        };
      }
    }
    case SESSION_MSG_OFFSET:
      return {
        ...state,
        messagesOffset: payload,
      };
    case LOAD_SESSION_MSG:
      const sesstionType = payload.sessionType
        ? payload.sessionType
        : payload.messageType;
      // sort messages in case of google session
      if (payload.messages && !googleType.includes(payload.sessionType)) {
        let userType = "";
        payload.messages = payload.messages.sort(
          (a: any, b: any, index: number) => {
            //let item = payload.messages[index];
            const dataA: Date = new Date(a.dateSent);
            const dateB: Date = new Date(b.dateSent);
            return dataA.getTime() - dateB.getTime();
          }
        );

        // mark the last message for client and agent
        // let userType = "";
        for (let i = 0; i < payload.messages.length; i++) {
          let item = payload.messages[i];
          if (i === 0) {
            userType = item.senderName;
          }
          // if sender type change then it is his last mesage
          if (userType !== item.senderName && i !== 0) {
            payload.messages[i - 1].last = true;
            userType = item.senderName;
          } else if (userType === item.senderName && i !== 0) {
            // if not then it is the same user
            payload.messages[i].last = false;
            userType = item.senderName;
          }

          // if it the last message in array
          if (i + 1 === payload.messages.length) {
            payload.messages[i].last = true;
          }
        }
      }

      // update Session Count
      let oldActive: any = sessionActive;
      let sessionMessage = state.sessionMessages;

      sessionMessage.getAnotherPatch = false;
      if (
        payload.hasOwnProperty("messages") &&
        payload?.messages?.length === AppConfig.GET_MESSAGE_PATCH_SIZE
      ) {
        sessionMessage.getAnotherPatch = true;
      }

      /*
      TODO: need to refactor the way target session is updated 
      */

      if (sessionActive[payload.sessionKey]) {
        let targetSession: object = sessionActive[payload.sessionKey];
        targetSession = {
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          unreadCount: null,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: { ...payload } }
            : {}),
          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),
          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
        let newAgentMsgs: any[] = [];
        let newClientMsgs: any[] = [];

        payload?.messages?.forEach((item: any) => {
          const mappedItem = {
            Text: item.message,
            Time: item.dateSent,
          };
          if (item.sessionKey === state.selectedSession) {
            if (item.senderType === "agent") {
              newAgentMsgs.push(mappedItem);
            } else if (item.senderType === "client") {
              newClientMsgs.push(mappedItem);
            }
          }
        });
        oldActive[payload.sessionKey] = targetSession;
        sessionMessage = {
          ...sessionMessage,
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: { ...payload } }
            : {}),
          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),
          //senderType:"client"
          //senderType:"agent"
          // filter messages to get agentmsgs and clientmsgs
          agentMsgs:
            sessionMessage?.messages?.length > 0 &&
            payload.sessionKey === state.selectedSession &&
            payload.hasOwnProperty("messages")
              ? [...newAgentMsgs, ...(sessionMessage.agentMsgs || [])]
              : payload.sessionKey === state.selectedSession &&
                !payload.hasOwnProperty("messages")
              ? [...(sessionMessage.agentMsgs || [])]
              : [...newAgentMsgs],
          clientMsgs:
            sessionMessage?.messages?.length > 0 &&
            payload.sessionKey === state.selectedSession &&
            payload.hasOwnProperty("messages")
              ? [...newClientMsgs, ...(sessionMessage.clientMsgs || [])]
              : payload.sessionKey === state.selectedSession &&
                !payload.hasOwnProperty("messages")
              ? [...(sessionMessage.clientMsgs || [])]
              : [...newClientMsgs],
          currentSessionAllMessagesLoaded:
            payload?.messages?.[0]?.sessionKey !== state.selectedSession,
          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
      }
      if (sessionClosed[payload.sessionKey]) {
        let targetSession: object = sessionClosed[payload.sessionKey];
        targetSession = {
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          unreadCount: null,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: payload }
            : {}),

          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),

          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
        sessionClosed[payload.sessionKey] = targetSession;
        sessionMessage = {
          ...sessionMessage,
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: { ...payload } }
            : {}),

          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),

          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
      }
      if (sessionInactive[payload.sessionKey]) {
        let targetSession: object = sessionInactive[payload.sessionKey];
        targetSession = {
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: { ...payload } }
            : {}),
          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),
          unreadCount: null,
          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
        sessionInactive[payload.sessionKey] = targetSession;
        sessionMessage = {
          ...sessionMessage,
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: payload }
            : {}),
          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),
          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
      }
      if (escaletedAssigned[payload.sessionKey]) {
        let targetSession: object = escaletedAssigned[payload.sessionKey];
        targetSession = {
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          unreadCount: null,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: payload }
            : {}),
          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),
          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
        escaletedAssigned[payload.sessionKey] = targetSession;
        sessionMessage = {
          ...sessionMessage,
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: payload }
            : {}),
          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),
          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
      }
      if (escalatedUnassigned[payload.sessionKey]) {
        let targetSession: object = escalatedUnassigned[payload.sessionKey];
        targetSession = {
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          unreadCount: null,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: payload }
            : {}),
          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),
          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
        escalatedUnassigned[payload.sessionKey] = targetSession;
        sessionMessage = {
          ...sessionMessage,
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: payload }
            : {}),
          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),
          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
      }
      if (
        !sessionActive[payload.sessionKey] &&
        !sessionInactive[payload.sessionKey] &&
        !sessionClosed[payload.sessionKey] &&
        !escaletedAssigned[payload.sessionKey] &&
        !escalatedUnassigned[payload.sessionKey]
      ) {
        let targetSession: object = {
          ...payload,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          unreadCount: null,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: payload }
            : {}),
          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),
          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
        sessionMessage = {
          ...sessionMessage,
          ...payload,
          ...targetSession,
          replayActive: payload?.replayActive,
          messages: payload.replies
            ? payload.replies
            : sessionMessage?.messages?.length > 0 &&
              payload.sessionKey === state.selectedSession &&
              payload.hasOwnProperty("messages")
            ? [...payload.messages, ...sessionMessage.messages]
            : payload.sessionKey === state.selectedSession &&
              !payload.hasOwnProperty("messages")
            ? [...sessionMessage.messages]
            : payload.messages,
          ...(sesstionType.includes(SessionEngine.twitter) &&
          sesstionType !== SessionEngine.twitterDM
            ? { tweets: payload }
            : {}),
          ...(sesstionType.includes(SessionEngine.instagram) &&
          sesstionType !== SessionEngine.instagramDM
            ? { instagram: payload }
            : {}),
          ...(email.includes(sesstionType) ? { emails: { ...payload } } : {}),
        };
      }
      // increment unread count
      let unread = 0;
      payload?.messages?.forEach((item: any) => {
        if (item.messageStatus === "UNREAD") {
          unread++;
        }
      });

      const selectedActiveSession: any = oldActive[payload.sessionKey];
      const selectedInactiveSession = sessionInactive[payload.sessionKey];
      const selectedClosedSession = sessionClosed[payload.sessionKey];
      const selectedEscalatedAssignSession =
        escaletedAssigned[payload.sessionKey];
      const selectedEscalatedUnassignSession =
        escalatedUnassigned[payload.sessionKey];

      let targetUpdate = sessionActive[payload.sessionKey]
        ? { sessionsActive: oldActive }
        : sessionClosed[payload.sessionKey]
        ? { sessionClosed: sessionClosed }
        : sessionInactive[payload.sessionKey]
        ? { sessionsInactive: sessionInactive }
        : escaletedAssigned[payload.sessionKey]
        ? { escaletedAssigned: escaletedAssigned }
        : escalatedUnassigned[payload.sessionKey]
        ? { escalatedUnassigned: escalatedUnassigned }
        : {};

      let selectedSessionData = selectedActiveSession
        ? selectedActiveSession
        : selectedInactiveSession
        ? selectedInactiveSession
        : selectedClosedSession
        ? selectedClosedSession
        : selectedEscalatedAssignSession
        ? selectedEscalatedAssignSession
        : selectedEscalatedUnassignSession
        ? selectedEscalatedUnassignSession
        : null;

      let clientInfo = {
        name: payload?.clientName,
        username: payload?.username,
        accountID: payload?.accountID,
        thumbURL: payload?.profileImage,
        companyID: payload?.channel?.CompanyID,
        sessionStatus: payload?.sessionStatus,
        sessionKey: payload?.sessionKey,
        clientID: payload?.clientID,
      };

      return {
        ...state,
        selectedSessionClientInfo: {
          ...state.selectedSessionClientInfo,
          ...clientInfo,
        },
        sessionMessages: sessionMessage,
        loadingMessages: false,
        ...targetUpdate,
        ...(state.openedSession?.sessionKey === payload.sessionKey
          ? { openedSession: { ...state.openedSession, unreadCount: 0 } }
          : {}),
        ...(payload.sessionStatus === SessionType.ASSIGNED &&
        state.globalUnreadCount - unread < 0
          ? { globalUnreadCount: state.globalUnreadCount }
          : {}),
        ...(payload.sessionStatus === SessionType.ASSIGNED_ESCALATED
          ? {
              globalEscalatedUnreadCount:
                state.globalEscalatedUnreadCount - unread,
            }
          : {}),
        ...(selectedSessionData
          ? { selectedSessionData: selectedSessionData }
          : {}),

        chatType: directMsg.includes(sesstionType)
          ? ChatType.directMsg
          : whatsappMsg.includes(sesstionType)
          ? ChatType.whatsapp
          : twitterPost.includes(sesstionType)
          ? ChatType.twitter
          : email.includes(sesstionType)
          ? ChatType.email
          : linkedInpost.includes(sesstionType)
          ? ChatType.linkedin
          : instagramPost.includes(sesstionType)
          ? ChatType.instagram
          : googleType.includes(sesstionType)
          ? ChatType.google
          : youtubeType.includes(sesstionType)
          ? ChatType.youtube
          : ChatType.none,
      };
    case CLEAR_SELECTED_SESSION:
      return {
        ...state,
        chatType: "",
        sessionMessages: {},
        msgLimitStatus: null,
        selectedSession: null,
        receiveNewMessage: null,
      };
    case TAKE_SESSION:
      return {
        ...state,
        openSession: true,
        openSessionStatus: null,
      };
    case TAKE_SESSION_SUCCESS: {
      if (payload.status === "SUCCESS") {
        return {
          ...state,
          openSession: false,
          openSessionStatus: payload,
        };
      } else {
        return {
          ...state,
          openSession: false,
          openSessionStatus: payload,
          chatType: "",
        };
      }
    }
    case ESCALATE_SESSION: {
      sessionActive[payload.sessionKey] = {
        ...sessionActive[payload.sessionKey],
        sessionStatus: SessionType.ESCALATED,
      };
      return {
        ...state,
        sessionsActive: sessionActive,
        activeCount: Object.keys(sessionActive).length,
      };
    }
    case SESSION_OPEN_SUCCESS: {
      //   let oldSessionInactive = sessionInactive;
      //   let oldSessionActive = sessionActive;
      let transferSession = {};
      if (
        payload.updateStatus === "SUCCESS" &&
        payload.type === "sessionEvent" &&
        payload.previousGroupID !== payload.groupID &&
        payload?.leaveStatus === "SUCCESS"
      ) {
        transferSession = { ...payload };
      }
      const newCounts = updateTotalSessionsByUserSessionOpen(
        payload,
        sessionInactive,
        sessionActive,
        state
      );
      let newSorted;

      if (
        filterIncomingEvents(
          payload,
          state.currentFilters,
          state?.isEnableSearch,
          SESSION_OPEN_SUCCESS
        )
      ) {
        //let typeOfSession: SessionType | string = "";
        if (payload.sessionStatus === SessionType.UNASSIGNED) {
          // console.log('unassigned session')
          //typeOfSession = SessionType.UNASSIGNED;
          delete sessionActive[payload.sessionKey];
          sessionInactive[payload.sessionKey] = payload;
          newSorted = sortSessions(sessionInactive);
          sessionInactive = newSorted;
          // console.log({sessionInactive,sessionActive,payload})
        } else if (payload.sessionStatus === SessionType.ASSIGNED) {
          // console.log('assigned session')
          //typeOfSession = SessionType.ASSIGNED;
          delete sessionInactive[payload.sessionKey];
          delete escaletedAssigned[payload.sessionKey];

          sessionActive[payload.sessionKey] = {
            ...payload,
            unreadCount: null,
          };
          newSorted = sortSessions(sessionActive);
          sessionActive = newSorted;
          // console.log({sessionInactive,sessionActive,payload})
        } else if (payload.sessionStatus === SessionType.ESCALATED) {
          //typeOfSession = SessionType.ESCALATED;
          delete escaletedAssigned[payload.sessionKey];
          sessionActive[payload.sessionKey] = payload;
          escalatedUnassigned[payload.sessionKey] = {
            ...payload,
            unreadCount: null,
          };
          newSorted = sortSessions(escalatedUnassigned);
          escalatedUnassigned = newSorted;
        } else if (payload.sessionStatus === SessionType.ASSIGNED_ESCALATED) {
          //typeOfSession = SessionType.ASSIGNED_ESCALATED;
          delete escalatedUnassigned[payload.sessionKey];
          escaletedAssigned[payload.sessionKey] = {
            ...payload,
            unreadCount: null,
          };
          sessionActive[payload.sessionKey] = payload;
          newSorted = sortSessions(escaletedAssigned);
          escaletedAssigned = newSorted;
        }
      }

      //todo remove comment form this

      //   console.log({typeOfSession})
      //   let targetUpdate =
      //     typeOfSession === SessionType.UNASSIGNED
      //       ? { sessionsInactive: sessionInactive,sessionsActive: sessionActive  }
      //       : typeOfSession === SessionType.ASSIGNED
      //       ? { sessionsActive: sessionActive ,sessionsInactive: sessionInactive,escaletedAssigned: escaletedAssigned }
      //       : typeOfSession === SessionType.ESCALATED
      //       ? { escalatedUnassigned: escalatedUnassigned ,sessionsActive: sessionActive,escaletedAssigned: escaletedAssigned }
      //       : typeOfSession === SessionType.ASSIGNED_ESCALATED
      //       ? { escaletedAssigned: escaletedAssigned,escalatedUnassigned: escalatedUnassigned }
      //       : {};
      let readCount =
        payload.activityLabel === "UPDATE_SESSION_TO_ASSIGNED" ||
        state.sessionsActive[payload.sessionKey]
          ? null
          : payload.unreadCount &&
            payload.sessionStatus === SessionType.UNASSIGNED &&
            state.globalUnreadCount - payload.unreadCount >= 0
          ? payload.activityLabel === "UPDATE_SESSION_TO_UNASSIGNED" &&
            state.sessionsInactive[payload.sessionKey]
            ? state.globalUnreadCount - payload.unreadCount
            : state.globalUnreadCount
          : null;
      let unreadCount =
        payload.activityLabel === "UPDATE_SESSION_TO_ASSIGNED_ESCALATED" ||
        state.escalatedUnassigned[payload.sessionKey]
          ? null
          : payload.unreadCount &&
            payload.sessionStatus === SessionType.ASSIGNED_ESCALATED
          ? state.globalEscalatedUnreadCount - payload.unreadCount
          : null;

      // check if this is the same opened session to update client info
      const checkUserInfo =
        payload?.sessionKey === state?.selectedSessionData?.sessionKey
          ? {
              ...state.selectedSessionClientInfo,
              name: payload.clientName,
              username: payload.username,
              accountID: payload.accountID,
              thumbURL: payload.profileImage,
              companyID: payload.channel.CompanyID,
              sessionStatus: payload.sessionStatus,
              sessionKey: payload.sessionKey,
              clientID: payload.clientID,
            }
          : null;

      return {
        ...state,
        ...(newCounts
          ? {
              inactiveCount: newCounts.newInactiveCount,
              activeCount: newCounts.newActiveCount,
            }
          : {}),
        ...(payload.sessionKey === state.selectedSession
          ? { selectedSessionData: payload }
          : {}),
        ...(readCount || readCount === 0
          ? { globalUnreadCount: readCount }
          : {}),
        ...(unreadCount ? { globalEscalatedUnreadCount: unreadCount } : {}),
        ...(checkUserInfo ? { selectedSessionClientInfo: checkUserInfo } : {}),
        sessionsActive: sessionActive,
        sessionsInactive: sessionInactive,
        escaletedAssigned: escaletedAssigned,
        escalatedUnassigned: escalatedUnassigned,
        receiveNewMessage: { ...payload },
        transferSession,
      };
    }
    case SESSION_CLOSED_SUCCESS: {
      let oldSessionsActive = sessionActive;
      let typeOfSession: SessionType | string = "";
      if (filterIncomingEvents(payload, state.currentFilters)) {
        if (oldSessionsActive[payload.sessionKey]) {
          typeOfSession = SessionType.ASSIGNED;
          delete oldSessionsActive[payload.sessionKey];
        }

        if (escaletedAssigned[payload.sessionKey]) {
          typeOfSession = SessionType.ASSIGNED_ESCALATED;
          delete escaletedAssigned[payload.sessionKey];
        }

        if (escalatedUnassigned[payload.sessionKey]) {
          typeOfSession = SessionType.ESCALATED;
          delete escalatedUnassigned[payload.sessionKey];
        }
        sessionClosed = {
          [payload.sessionKey]: payload,
          ...sessionClosed,
        };
      }

      let targetUpdate =
        typeOfSession === SessionType.ASSIGNED
          ? { sessionsActive: oldSessionsActive }
          : typeOfSession === SessionType.ESCALATED
          ? { escalatedUnassigned: escalatedUnassigned }
          : typeOfSession === SessionType.ASSIGNED_ESCALATED
          ? { escaletedAssigned: escaletedAssigned }
          : {};

      //   console.log({ escaletedAssigned, escalatedUnassigned })
      //   console.log({sessionData:state.selectedSessionData?.sessionKey,payload})
      const newActiveCount =
        payload.currentUser.name === payload.agentName ||
        payload.currentUser.role === UserRoles.SUPERVISOR
          ? state.activeCount - 1
          : state.activeCount;

      let newGlobalUnreadCount =
        payload.activityLabel === "UPDATE_SESSION_TO_CLOSED" &&
        payload.unreadCount &&
        payload.sessionStatus === SessionType.CLOSED &&
        state.globalUnreadCount - payload.unreadCount >= 0
          ? state.globalUnreadCount - payload.unreadCount
          : null;

      return {
        ...state,
        ...targetUpdate,
        sessionsClosed: sessionClosed,
        activeCount: newActiveCount,
        ...(payload.currentUser.role === UserRoles.SUPERVISOR &&
        state.selectedSession === payload.sessionKey
          ? { selectedSessionData: omit(payload, "currentUser") }
          : payload.currentUser.role === UserRoles.SUPERVISOR
          ? {}
          : { selectedSessionData: null }),
        ...(newGlobalUnreadCount || newGlobalUnreadCount === 0
          ? { globalUnreadCount: newGlobalUnreadCount }
          : {}),
      };
    }
    case LEAVE_SESSION_SUCCESS: {
      if (
        payload.status === "SUCCESS" &&
        (sessionActive[payload.sessionKey] ||
          escaletedAssigned[payload.sessionKey])
      ) {
        return {
          ...state,
          openSession: false,
          openedSession: null,
          openSessionStatus: payload,
          ...(state.selectedSession === payload.sessionKey
            ? { chatType: "" }
            : {}),
          selectedSession:
            state.selectedSession === payload.sessionKey
              ? null
              : state.selectedSession,
          ...(payload.sessionKey === state.selectedSession
            ? { selectedSessionData: payload }
            : {}),
        };
      } else {
        return {
          ...state,
          openSession: false,
          openedSession: null,
          openSessionStatus: payload,
        };
      }
    }
    case SEND_MSG_API: {
      return {
        ...state,
        sendingMessage: true,
      };
    }
    case SEND_UPDATE_PENDING: {
      let messages;
      //   console.log({ SEND_UPDATE_PENDING: payload });
      let messageValue = [...state?.sessionMessages?.messages];
      if (commonMsgType.includes(payload.messageType)) {
        // console.log({ messageValue })
        // Twitter message
        messages = messageValue?.map((item: any) => {
          if (item.vendorMessageKey === payload.VendorParentIDs) {
            item.replies = [...(item.replies || []), payload];
            return item;
          } else {
            return item;
          }
        });
      }

      // direct message
      messages = messageValue ? [...messageValue, payload] : [payload];
      return {
        ...state,
        sessionMessages: {
          ...state.sessionMessages,
          messages,
          ...(commonMsgType.includes(payload.messageType)
            ? {
                tweets: {
                  ...state.sessionMessages?.tweets,
                  replies: [...messages],
                },
              }
            : {}),
        },
      };
    }
    // TODO: need to recheck it is behavior is correct or not for twitter
    case MESSAGE_STATUS: {
      const {
        messageDetails,
        sessionKey,
        messageOutKey,
        messageKey,
        status,
        failMsg,
      } = payload;
      let messageValue = state?.sessionMessages?.messages;
      // if messageOutkey,status and failMsg these value returned in error
      if (
        state?.sessionMessages &&
        messageValue &&
        sessionKey === state?.selectedSession
      ) {
        const messagesIndex = messageValue
          .map((item: any) => item.messageKey)
          .indexOf(
            messageKey
              ? messageKey
              : messageOutKey
              ? messageOutKey
              : messageDetails[0].messageKey
          );
        const messages = state?.sessionMessages?.messages;

        if (messagesIndex === -1) {
          messages.push({
            ...(messageDetails
              ? { ...messageValue[messagesIndex], ...messageDetails[0] }
              : messageValue[messagesIndex]),
            ...(status ? { status, failMsg } : null),
            // last: true
          });
        }

        if (
          messages[messages.length - 2] &&
          messages[messages.length - 2].senderKey ===
            messages[messages.length - 1].senderKey
        ) {
          messages[messages.length - 2].last = false;
        }
        // console.log({ messagesIndex, messageDetails: messageDetails[0] });
        if (messagesIndex !== -1) {
          messages.splice(messagesIndex, 1, {
            ...(messageDetails
              ? { ...messageValue[messagesIndex], ...messageDetails[0] }
              : messageValue[messagesIndex]),
            ...(status ? { status, failMsg } : null),
            // last: true
          });
        }
        // If my session was linkedIn update the reply status
        if (
          [SessionEngine.linkedinComment].includes(
            state.sessionMessages.messageType
          )
        ) {
          state.sessionMessages = updateReplyStatus(
            state.sessionMessages,
            payload
          );
        }

        return {
          ...state,
          sessionMessages: {
            ...state.sessionMessages,
            messages: [...messages],
            ...(state.sessionMessages.messageType.includes(
              SessionEngine.twitter
            ) && state.sessionMessages.messageType !== SessionEngine.twitterDM
              ? {
                  tweets: {
                    ...state.sessionMessages?.tweets,
                    replies: [...messages],
                  },
                }
              : {}),
          },
        };
      } else {
        return state;
      }
    }
    case SET_CLIENT_INFO: {
      return {
        ...state,
        selectedSessionClientInfo: {
          ...state.selectedSessionClientInfo,
          ...payload,
          sessionStatus: state.selectedSessionData?.sessionStatus,
        },
        selectedSessionData: {
          ...state.selectedSessionData,
          clientName: payload.name,
        },
        /* sessionsActive: {
          ...sessionActive,
          [state?.selectedSession ?? ""]: {
            ...sessionActive[state?.selectedSession ?? ""],
            clientName: payload.name,
          },
        }, */
      };
    }
    case GET_CLIENT_INFO: {
      return {
        ...state,
        selectedSessionClientInfo: {
          ...payload,
          sessionStatus: state.selectedSessionData?.sessionStatus,
        },
      };
    }
    case SAVE_DRAFT_MESSAGE: {
      return {
        ...state,
        draftMessages: {
          ...state.draftMessages,
          [payload.selectedSession as string]: payload.draftMessages,
        },
      };
    }
    case DELETE_DRAFT_MESSAGE: {
      const newdraftMessages = state.draftMessages;
      delete newdraftMessages[payload as keyof typeof newdraftMessages];
      return {
        ...state,
        draftMessages: {
          ...newdraftMessages,
        },
      };
    }
    case TRANSFER_GROUP: {
      return {
        ...state,
        selectedSessionData: null,
        selectedSession: null,
        chatType: "",
        sessionMessages: null,
      };
    }
    case RETURN_TO_AGENT: {
      return {
        ...state,
        chatType: "",
      };
    }
    case SESSION_AGENT_FILTER: {
      let dataFilter = filterSessions(
        state.groupFilter,
        payload.filter,
        { ...sessionActive },
        { ...sessionInactive },
        { ...escaletedAssigned },
        { ...sessionClosed },
        { ...escalatedUnassigned }
      );

      return {
        ...state,
        agentFilter: payload.filter,
        ...dataFilter,
      };
    }
    case SESSION_GROUP_FILTER: {
      let dataFilter = filterSessions(
        payload.filter,
        state.agentFilter,
        { ...sessionActive },
        { ...sessionInactive },
        { ...escaletedAssigned },
        { ...sessionClosed },
        { ...escalatedUnassigned }
      );
      return {
        ...state,
        groupFilter: payload.filter,
        ...dataFilter,
      };
    }
    case APPLY_FILTERS: {
      return {
        ...state,
        currentFilters: { ...payload },
      };
    }
    case UPDATE_VALUE_ENABLE_SEARCH: {
      return {
        ...state,
        isEnableSearch: payload,
      };
    }
    case START_VIDEO_CALL: {
      return {
        ...state,
        joinedVideoCall: true,
      };
    }
    case END_VIDEO_CALL: {
      return {
        ...state,
        joinedVideoCall: false,
      };
    }
    case VIDEO_CALL_PARTICIPANTS: {
      return {
        ...state,
        videoCallParticipants: payload.Participants || [],
      };
    }
    case RECEIVE_NEW_REACTION: {
      //returns empty object if no property is found
      const { property, value } = getReactionProperty(
        payload.type,
        payload.createdAt
      );

      if (property) {
        return updateTweetsAndReplies(state, payload, property, value);
      } else {
        return state;
      }
    }
    case LOADING_REACTION_LIST: {
      return {
        ...state,
        loadingReactionList: true,
      };
    }
    case RECEIVE_REACTION_LIST: {
      //sort by newer before return
      const sortedActive: any = payload?.Activities?.sort(
        (a: any, b: any) =>
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
      );

      return {
        ...state,
        loadingReactionList: false,
        reactionList: {
          ...payload,
          Activities: sortedActive,
        },
      };
    }
    case UPDATE_MULTI_SELECTED_SESSIONS: {
      return {
        ...state,
        multiSelectedSessions: payload,
      };
    }
    case UPDATE_CURRENT_SESSION_TYPE: {
      return {
        ...state,
        currentSessionType: payload,
      };
    }
    default:
      return state;
  }
}
