import { SessionEngine } from "enums/sessionEngine";

export const directMsg: string[] = [
  SessionEngine.facebookDM,
  SessionEngine.twitterDM,
  SessionEngine.instagramDM,
  SessionEngine.telegramDM,
  SessionEngine.webchat,
  SessionEngine.livechat,
  SessionEngine.webChatVideo,
];

export const whatsappMsg: string[] = [
  SessionEngine.whatsapp,
  SessionEngine.whatsappODA,
  SessionEngine.whatsappVonage,
];

export const twitterPost: string[] = [
  SessionEngine.twitterTweet,
  SessionEngine.twitterMention,
  SessionEngine.twitterReplay,
  SessionEngine.twitterRetweet,
  SessionEngine.twitterPost,
  SessionEngine.twitterQuote,
];

export const email: string[] = [
  SessionEngine.email,
  SessionEngine.outlookEmail,
  SessionEngine.mainEmail,
  SessionEngine.gmailEmail,
  SessionEngine.microsoft365Email,
  SessionEngine.microsoft365EmailReply,
  SessionEngine.exchangeEmail,
  SessionEngine.exchangeEmailReply,
];

export const linkedInpost: string[] = [
  SessionEngine.linkedinMention,
  SessionEngine.linkedinPost,
  SessionEngine.linkedinComment,
  SessionEngine.linkedinReply,
];

export const instagramPost: string[] = [
  SessionEngine.instagramComment,
  SessionEngine.instagramReplay,
  SessionEngine.instagramPost,
];

export const commonMsgType: string[] = [
  SessionEngine.twitterTweet,
  SessionEngine.twitterReplay,
  SessionEngine.twitterMention,
  SessionEngine.linkedinComment,
  SessionEngine.linkedinReply,
  SessionEngine.instagramComment,
  SessionEngine.youtubeReply,
  SessionEngine.googleReview,
  ...email,
];

export const googleType: string[] = [
  SessionEngine.googleMention,
  SessionEngine.googleReview,
  SessionEngine.googleLocation,
];

export const youtubeType: string[] = [
  SessionEngine.youtubeComent,
  SessionEngine.youtubeVideo,
];
